.App {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  background-color: rgb(235, 196, 196);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  padding: 2rem;
}

svg {
  margin: 0 auto;
}

@media (max-width:900px) {
  .App {
    flex-direction: column;
  }
}

.flex-vert {
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-vert:first-child {
  
}

@media (min-width:900px) {
  

  .flex-vert:first-child {
    
    margin-top: 0;
    margin-bottom: 0;
  }

  .flex-vert  {
    width: 43%;
  }
}

a {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
}
