.color-generator {
    width: 100%;
    height: 350px;
    border-radius: 15px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    margin-top: .7rem;
}

.color-button {
    border: none;
    padding: .6rem;
    border-radius: 15px;
    font-size: 16px;
    background-color: whitesmoke;
    margin: .5rem 0;
    row-gap: 1rem;
    cursor: pointer;
    transition: .2s ease;
}

@media (min-width:900px) {
    .color-button {

        font-size: 20px;
        padding: 1rem;
    }

  }

.complete {
    width: 100%;
}

.color-button:hover {
    background-color: rgb(224, 213, 199);
}

.color-correct {
    background-color: rgb(150, 226, 114) !important;
}

.color-incorrect {
    background-color: rgb(231, 100, 68) !important;
}